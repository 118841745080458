import React, { useEffect, useState, useCallback, useContext } from 'react';
import _, { filter, has } from 'lodash';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import PropTypes from 'prop-types';
import { DropdownMenuButton } from '@jutro/components';
import { Link as LinkComponent } from 'gw-components-platform-react';
import appConfig from 'app-config';
import { JobService, SubmissionService } from 'gw-capability-gateway';
import {
    DocumentsUtil,
    ServiceErrorUtil,
    WniDateUtil,
    WniProductsUtil,
    WniAccountsUtil,
    SummaryUtil
} from 'wni-portals-util-js';
import { JobUtil } from '@xengage/gw-portals-util-js';
import { WniDocumentRetrievalService, WniAccountQuoteService, WniSubmissionService } from 'wni-capability-gateway';
import {
    QuoteAdditionalProductComponent
} from 'wni-capability-gateway-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { ServiceManager } from '@jutro/legacy/services';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useWniModal } from 'wni-components-platform-react';
import { useProductsData } from 'wni-portals-util-react';
import { IssueApprovedQuotesPopup } from 'wni-capability-account-quotes-react';
import { ProductsSelectorPopup } from 'wni-capability-account-commercial-react';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import gatewayMessages from 'gw-capability-gateway-react/gateway.messages';
import { DropdownMenuLink } from '@jutro/router';
import { Button, CurrencyField } from '@jutro/legacy/components';
import messages from '../AccountSummary.messages';
import metadata from './PendingTransactions.metadata.json5';

const {
    isCLAccount,
    COMMERCIAL_ACCOUNT_CODE
} = WniAccountsUtil;

const getDisplayTypeName = (type) => {
    switch (type) {
        case 'Submission':
            return 'New Submission';
        default:
            return type;
    }
};
const { lobQuoteURL } = appConfig;
const accountSummaryConstants = {
    ProductFilterOptionAllCode: 'all',
    TransactionFilterOptionAllCode: 'all',
};

function PendingTransactions(props) {
    const {
        accountNumber,
        accountType,
        accountHolder: {
            accountEligibity_Ext: accountEligibity,
            primaryAddress: {
                state,
                postalCode
            } = {}
        } = {},
        activePolicies,
        data,
        refreshPrendingTransactions = _.noop,
        selectedAgency,
        history
    } = props;

    const modalApi = useWniModal();
    const useAuthenticationData = useAuthentication();
    const { authHeader, authUserData: {businessData_Ext: { systemDate, enableCommercialLine } ={}, userType: currentUserType } = {} } = useAuthenticationData;

    const translator = useTranslator();
    const {  getProductEffectiveDate, getProductVisibleForState, getAvailableProducts, getAvailableEffectiveDate } = useProductsData();
    const {
        interactionModel,
        loadingMask: { setLoadingMask }
    } = useDependencies(['interactionModel', 'loadingMask', 'domainCompany']);
    const localeService = ServiceManager.getService('locale-service');
    const defaultCountryCode = localeService.getDefaultCountryCode();
    const [tableData, updateTableData] = useState(data);
    const [selection, updateSelection] = useState([]);
    const [selectedVersionMap, updateSelectedVersionMap] = useState({});
    const [productFilter, updateProductFilter] = useState('all');
    const [transactionFilter, updateTransactionFilter] = useState('all');
    const isCL = isCLAccount(accountType);

    const getSelectedVersionPublicID = (job) => {
        const jobNumber = _.get(job, 'jobNumber');
        const selectedVerionsPublicID = _.get(selectedVersionMap, jobNumber);
        const defaultSelectedVersionPublicID = _.get(job, 'selectedVersion');
        return selectedVerionsPublicID || defaultSelectedVersionPublicID;
    };

    const getSelectedVersion = (job) => {
        const versions = _.get(job, 'versions', []);
        const selectedVersionPublicID = getSelectedVersionPublicID(job);
        const selectedVersion = versions.find(
            (version) => version.publicID === selectedVersionPublicID
        );
        return selectedVersion;
    };


    const generateTableData = (newData) => {
        let cloneNewData = _.clone(newData);
        if(!isCL) {
            cloneNewData = newData.filter((item) => item.type !== 'Renewal');
        }
        return cloneNewData.map((item) => {
            const selectedVersion = getSelectedVersion(item) || {};
            const productCode = _.get(item, 'product.productCode');
            const jobStatus = selectedVersion.status;
            return {
                ...item,
                productName: WniProductsUtil.getProductNameByLinePattern(item.product),
                productCode: productCode,
                effectiveDate: WniDateUtil.formatDateWithPattern(
                    item.transactionEffectiveDate
                ),
                expirationDate: WniDateUtil.formatDateWithPattern(
                    item.policyExpirationDate
                ),
                totalPremium: selectedVersion.totalCost,
                selectedVersionStatus: jobStatus,
                transactionTypeDisplay: getDisplayTypeName(item.type),
                unselectable: _.get(item, 'type') !== 'Submission' || jobStatus !== 'Approved'
            };
        });
    };

    useEffect(() => {
        const initData = _.cloneDeep(data);
        updateTableData(generateTableData(initData));
    }, [data]);

    const successCallback = () => {
        setLoadingMask(false);
    };
    const errorCallback = () => {
        setLoadingMask(false);
        modalApi.showConfirm({
            title: 'Error',
            message: ServiceErrorUtil.prependWithFriendMessage(),
            status: 'error',
            icon: 'gw-error-outline',
            confirmButtonText: commonMessages.ok,
        });
    };
    const onPrintClick = async (summaryDocument) => {
        setLoadingMask(true);
        await DocumentsUtil.tryDownloadDocument(
            summaryDocument,
            authHeader,
            history,
            WniDocumentRetrievalService,
            successCallback,
            errorCallback
        );
    };

    const getQuoteSummaryCell = (rowData) => {
        const isApproved = _.get(rowData, 'status') === 'Approved';
        const summaryDocument = _.get(rowData, 'summaryDocument');
        if (isApproved && !_.isNil(summaryDocument)) {
            return (
                <Button
                    className="btn-link"
                    type="text"
                    onClick={() => onPrintClick(summaryDocument)}
                >
                    Print
                </Button>
            );
        }
        return '-';
    };

    const getTotalPremium = (rowData, rowIndex) => {
        const accountHolderSubType = _.get(rowData, 'accountHolder.subtype')
        const type = _.get(rowData, 'type');
        const createUserType = _.get(rowData, 'createUser_Ext.userType')
        let showPremium = true
        if (accountHolderSubType === 'Company') {
            /**
             * POI-48276 01.0003
             * For pending transactions if a transaction is quoted or approved and NOT locked, the premium value should display. 
             * Otherwise the premium value should show - in this table.
             * 
             */
            showPremium = !_.get(rowData, 'locked') 
                && !SummaryUtil.isLockedDueToCreatedbyUW(type, createUserType, currentUserType)

        } else {
            showPremium = _.get(rowData, 'status') === 'Approved'
        }
        if (!showPremium) {
            return '-'
        }
        const totalPremium = _.get(rowData, 'totalPremium');
        return <CurrencyField
            id={`premium_${rowIndex}`}
            value={totalPremium}
            dataType="object"
            readOnly
            hideLabel
        />
    }

    const getTransactionTypeIsViewableInXCenter = (rowData) => {
        const status = _.get(rowData, 'status');
        return status
            ? status.toLowerCase() !== 'reinstatement' &&
                  status.toLowerCase() !== 'rewrite'
            : false;
    };

    const showOpenJobInXCenter = (type) => {
        return (
            type === 'Submission' ||
            type === 'PolicyChange' ||
            type === 'Cancellation' ||
            type === 'Renewal'
        );
    };

    const onGotoWizardLinkClick = (rowData) => {
        const {
            jobNumber,
            product: { productCode } = {},
            accountHolder: { primaryAddress: { postalCode: productPostalCode } } = {},
        } = rowData;
        if (!_.isNil(lobQuoteURL[productCode])) {
            const nextLocation = {
                isReadOnly: false,
                quoteentry: {
                    postalCode: productPostalCode,
                    quoteID: jobNumber
                }
            };
            history.push(lobQuoteURL[productCode], nextLocation);
        } else {
            JobUtil.openJobInXCenter(jobNumber);
        }
    };

    const getPolicySummaryLink = (policyNumber) => {
        return interactionModel.goToPage(accountType, history, 'policySummary', accountNumber, policyNumber);
    };

    const getJobTypeCell = (rowData, index, { path }) => {
        const {
            type,
            jobNumber,
            canUserView,
            quoteFlow_Ext: quoteFlow,
            policyNumber,
            // oossliceDates_Ext: oossliceDate,
            transactionEffectiveDate,
            //
            preemptiveTransaction_Ext: isPreemptiveTransaction,
            oosTransaction_Ext: isOosTransaction,
        } = rowData;
        const transactionTypeIsViewableInXCenter = getTransactionTypeIsViewableInXCenter(rowData);
        const shouldBlockChange = isPreemptiveTransaction || isOosTransaction;

        if (!canUserView) {
            return null;
        }
        let fieldProps = {};
        if (quoteFlow === 'draft') {
            switch (type) {
                case 'Submission':
                    fieldProps = {
                        onClick: () => onGotoWizardLinkClick(rowData),
                    };
                    break;
                default:
                    fieldProps = {
                        onClick: () => getPolicySummaryLink(policyNumber),
                    };
                    break;
            }
        }

        if (transactionTypeIsViewableInXCenter) {
            if (!showOpenJobInXCenter(type)) {
                fieldProps = {
                    onClick: () => JobUtil.openJobInXCenter(jobNumber),
                };
            } else if (type === 'PolicyChange' && shouldBlockChange) {
                // default: isOosTransaction
                fieldProps = {
                    onClick: () => {
                        modalApi.showAlert({
                                title: translator(messages.blockChangeTitle),
                            message: isPreemptiveTransaction ? messages.preemptiveTransaction : messages.oosTransaction,
                                status: 'info',
                                icon: 'gw-error-outline',
                                size: 'lg',
                            }).catch(_.noop);   // for 'close' operation
                    }
                }
            } else {
                fieldProps = {
                    to: JobUtil.getJobDetailURLByJobType(type, jobNumber),
                };
            } 
        }

        return <LinkComponent {...fieldProps}>{rowData[path]}</LinkComponent>;
    };

    const blockChangeAlert = (isPreemptiveTransaction) => {
        // trigger the block popup when this shouldBlockChange and it is Policy Change and it is and CL product
        return modalApi.showAlert({
            title: messages.blockChangeTitle,
            message: isPreemptiveTransaction ? messages.preemptiveTransaction : messages.oosTransaction,
            status: 'info',
            icon: 'gw-error-outline',
        }).catch(_.noop)
    }

    const getViewPolicyCell = useCallback(
        (rowData) => {
            const {
                type,
                jobNumber,
                canUserView,
                policyNumber,
                oosTransaction_Ext: isOosTransaction,
                preemptiveTransaction_Ext: isPreemptiveTransaction,
                product: {
                    productCode,
                } = {}
            } = rowData;
            let fieldProps = {};
            let displayNumber;
            let hasDom = true;
            const transactionTypeIsViewableInXCenter = getTransactionTypeIsViewableInXCenter(rowData);
                switch (type) {
                    case 'Submission':
                        if (canUserView && transactionTypeIsViewableInXCenter) {
                            if (!showOpenJobInXCenter(type)) {
                                fieldProps = {
                                    onClick: () => JobUtil.openJobInXCenter(jobNumber),
                                };
                            } else {
                                fieldProps = {
                                        to: JobUtil.getJobDetailURLByJobType(type, jobNumber),
                                    };
                            }
                            displayNumber = jobNumber;
                        } else {
                            hasDom = false;
                        }
                        break;
                    default:
                        if (!policyNumber || !accountNumber) {
                            hasDom = false;
                        } else {
                            const shouldBlockChange = isPreemptiveTransaction || isOosTransaction;
                        if (shouldBlockChange && type === 'PolicyChange' && WniProductsUtil.isCLProduct(productCode)) {
                                fieldProps = {
                                    onClick: () => blockChangeAlert(isPreemptiveTransaction)
                                }
                            } else {
                                fieldProps = {
                                    onClick: () => getPolicySummaryLink(policyNumber),
                                };
                            };
                            displayNumber = policyNumber;
                        }
                        break;
                }
            return hasDom ? <LinkComponent {...fieldProps} >{displayNumber}</LinkComponent> : '-';
        },
        [accountNumber, interactionModel]
    );

    const generateVersionDisplayName = (version, rowData) => {
        const selectedVersionPublicID = getSelectedVersionPublicID(rowData);
        const isSelectedVersion = version.publicID === selectedVersionPublicID;

        const policyType = version.policyType ? `${version.policyType} ` : '';

        let versionDisplayName;
        const productCode = _.get(rowData, 'product.productCode');
        switch (productCode) {
            case 'HOPHomeowners': {
                versionDisplayName = version.hocoverageForm
                    ? `${policyType}${version.hocoverageForm.toUpperCase()} ${version.branchName
                    } - ${version.status}`
                    : `${policyType}${version.branchName} - ${version.status}`;
                break;
            }
            case 'DwellingProperty': {
                versionDisplayName = `${version.branchName} - ${version.status}`;
                break;
            }
            default: {
                versionDisplayName = `${policyType}${version.branchName} - ${version.status}`;
            }
        }
        return `${isSelectedVersion ? '* ' : ''}${versionDisplayName}`;
    };

    const onVersionChange = (
        jobNumber,
        beforeSelectedVersion,
        selectedVersion
    ) => {
        if (selectedVersion === beforeSelectedVersion) {
            return;
        }
        const newSelectedVersionMap = _.cloneDeep(selectedVersionMap);
        _.set(newSelectedVersionMap, jobNumber, selectedVersion);
        updateSelectedVersionMap(newSelectedVersionMap);
    };

    const getVersionCell = (rowData) => {
        const { jobNumber, versions = [] } = rowData;
        const selectedVersionPublicID = getSelectedVersionPublicID(rowData);

        if (versions.length === 1) {
            const selectedVersion = versions.find(
                (version) => version.publicID === selectedVersionPublicID
            );
            return generateVersionDisplayName(selectedVersion, rowData);
        }

        return (
            <DropdownMenuButton
                icon="gw-expand-more"
                id="dropdownMenuButton"
                className="dropDownMenuIconbtn btnAction"
                menuClassName="dropDownMenuList"
                alignRight
            >
                {versions.map((version) => (
                    <DropdownMenuLink
                        key={version.publicID}
                        onClick={() =>
                            onVersionChange(
                                jobNumber,
                                selectedVersionPublicID,
                                version.publicID
                            )
                        }
                    >
                        {generateVersionDisplayName(version, rowData)}
                    </DropdownMenuLink>
                ))}
            </DropdownMenuButton>
        );
    };

    const cleanSelectedVersionInfoForJob = (jobNumber) => {
        const newSelectedVersionMap = _.cloneDeep(selectedVersionMap);
        _.unset(newSelectedVersionMap, jobNumber);
        updateSelectedVersionMap(selectedVersionMap);
    };

    const onWithdrawButtonClick = (rowData) => {
          const {
            jobNumber,
            type,
        } = rowData;

        const displayTypeName = getDisplayTypeName(type);
        modalApi
            .showConfirm({
                title: messages.withdrawJob,
                message: translator(messages.sureWithDrawJob, {
                    jobType: displayTypeName,
                    quoteNumber: jobNumber,
                }),
                status: 'warning',
                icon: 'gw-error-outline',
                confirmButtonText: commonMessages.ok,
            })
            .then((result) => {
                if (result === 'cancel' || result === 'close') {
                    return;
                }
                JobService.withdrawJobByJobNumber(jobNumber, authHeader).then(
                    () => {
                        // handle withdraw success
                        cleanSelectedVersionInfoForJob(jobNumber);
                        refreshPrendingTransactions(accountNumber);
                    },
                    () => {
                        // handle withdraw failed
                        modalApi.showAlert({
                            title: gatewayMessages.modalError,
                            message: messages.failedWithdrawSubmission,
                            status: 'error',
                            icon: 'gw-error-outline',
                            confirmButtonText: commonMessages.ok,
                        });
                    }
                );
            })
            .catch(_.noop);
    };

    const startSubmission = async (productCode, countryCode) => {
        setLoadingMask(true);
        const effectiveDate = getProductEffectiveDate(productCode) || systemDate;
        const SubmissionResponse = await SubmissionService
                .createSubmission({
                    accountNumber: accountNumber,
                    country: countryCode,
                    effectiveDate: effectiveDate,
                    producerCode: selectedAgency.code,
                    producerCodePublicID_Ext: selectedAgency.publicID,
                    productCode: productCode,
                    state: state,
                }, authHeader);
        setLoadingMask(false);

        const jobID = SubmissionResponse.jobNumber;
        if (!_.isNil(jobID) && !_.isNil(lobQuoteURL[productCode])) {
            await WniSubmissionService.addRecentlyViewedSubmission(jobID, authHeader);
            history.push(lobQuoteURL[productCode], {
                isReadOnly: false,
                quoteentry: {
                    postalCode: postalCode,
                    quoteID: jobID,
                }
            });
        }
    };
    
    const handleNewSubmission = (product) => {
        const { code: productCode } = product;
        // POI-56611: User can create monoline cp line on PE side when click quote additional product button
        if(accountType === COMMERCIAL_ACCOUNT_CODE ) {
            return history.push({
                pathname: `/cl-account-quotes/${accountNumber}/policy-holder`,
                state: {
                    productSelected: [productCode]
                }
            });
        }

        startSubmission(productCode, defaultCountryCode);
    };

    const handleNextBtnClick = () => {
        let ids = '';
        _.each(selection, (quoteID) => {
            ids += `${quoteID}_`;
        })
        ids = ids.substring(0, ids.length - 1);
        history.push('/aqpayment', {
            jobNumber: `${ids}`,
            payStatus: 'init'
        });
    };
    const issueApprovedQuotes = async() => {
        const isPUSuggested = await WniAccountQuoteService.isPUSuggested(accountNumber, authHeader);
        const approvedQuotes = tableData.filter((job) => selection
            .some((selectedJobNumber) => selectedJobNumber === job.jobNumber));
        const componentProps = {
            size: 'lg',
            activePolicies: activePolicies,
            approvedQuotes: approvedQuotes,
            onNextBtnClick: handleNextBtnClick,
            isPUSuggested: isPUSuggested,
            handleNewSubmission,
            productsMap: getAvailableProducts(accountType, state),
            accountType
        };
        modalApi.showModal(<IssueApprovedQuotesPopup {...componentProps} />);
    };

    const createNewQuoteForCL = () => {
        if (accountEligibity && !_.isEmpty(accountEligibity)) {
            const componentProps = {
                accountNumber,
                getAvailableEffectiveDate,
                getAvailableProducts,
                history
            };
            modalApi.showModal(<ProductsSelectorPopup {...componentProps} />);
        } else {
            return history.push({
                pathname: `/cl-account-quotes/${accountNumber}/policy-holder`,
                state: {
                    baseState: state
                }
            });
        }     
    };

    const getProductFilterAvailableValue = () => {
        const options = [{
            code: 'all',
            name: translator(messages.productFilterAllProductsItemName),
        }];
        if (_.isNil(data)) {
            return options;
        }
        data.forEach((prendingTransaction) => {
            const productCode = _.get(prendingTransaction, 'product.productCode');
            if (!options.some((option) => option.code === productCode)) {
                options.push({
                    code: productCode,
                    name: WniProductsUtil.getProductName(productCode)
                });
            }
        });
        return options;
    };

    const getTransactionFilterAvailableValue = () => {
        const options = [{
            code: 'all',
            name: translator(messages.productFilterAllTransactionsItemName),
        }];
        if (_.isNil(data)) {
            return options;
        }
        data.forEach((item) => {
            if (!options.some((option) => option.code === item.type)) {
                options.push({
                    code: item.type,
                    name: getDisplayTypeName(item.type)
                });
            }
        });
        return options;
    };
    
    const renderAction = (item) => {
        const {
            product: {
                productCode
            } = {},
            policyState
        } = item;

        const isProductEnabled = getProductVisibleForState(productCode, policyState);
        if(!isProductEnabled) {
            return '-';
        };
        return <Button icon='gw-delete' onClick={() => onWithdrawButtonClick(item)} className="wni-button-icon" />
    };

    const handleProductFilterChange = (value) => {
        updateProductFilter(value);
    };
    const handleTransactionTypeChange = (value) => {
        updateTransactionFilter(value);
    };

    useEffect(() => {
        const newData = _.cloneDeep(data);
        const generateData = generateTableData(newData);
        let filterData = generateData;
        if (productFilter !== 'all') {
            filterData = filterData.filter((item) => item.productCode === productFilter)
        };
        if (transactionFilter !== 'all') {
            filterData = filterData.filter((item) => item.type === transactionFilter);
        }
        updateTableData(filterData)
    }, [productFilter, transactionFilter]);

    const productFilterAvailableValue = getProductFilterAvailableValue();
    const transationFilterAvailableValue = getTransactionFilterAvailableValue();
    const overrides = {
        openTransactionDataTable: {
            data: tableData,
            onSelectionChange: (rows) => updateSelection(rows),
            selectedRows: selection
        },
        issueApprovedQuotesButton: {
            disabled: selection.length === 0 || (isCL && !enableCommercialLine)
        },
        createNewQuoteForCL: {
            visible: isCL,
            disabled: (isCL && !enableCommercialLine),
            content: isCL? messages.createNewQuoteCL:messages.createNewQuotePL
        },
        createNewQuoteForPL: {
            visible: !isCL,
            accountType,
            accountNumber,
            baseState: state,
            startSubmissionFn: startSubmission,
        },
        productFilter: {
            availableValues: productFilterAvailableValue,
            value: productFilter,
            onValueChange: handleProductFilterChange,
        },
        transactionFilter: {
            availableValues: transationFilterAvailableValue,
            value: transactionFilter,
            onValueChange: handleTransactionTypeChange,
        }
    };
    const resolvers = {
        resolveCallbackMap: {
            getVersionCell,
            getQuoteSummaryCell,
            getTotalPremium,
            onWithdrawButtonClick,
            getJobTypeCell,
            getViewPolicyCell,
            issueApprovedQuotes,
            createNewQuoteForCL,
            renderAction
        },
        resolveComponentMap: {
            quoteAdditionalProductComponent : QuoteAdditionalProductComponent,
        },
    };
    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={{}}
            overrideProps={overrides}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

export default PendingTransactions;
