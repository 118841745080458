import { useTranslator } from '@jutro/locale';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import _ from 'lodash';
import React, { useContext, useState, useEffect } from 'react';
import { Icon } from '@jutro/components';
import { useWniModal } from 'wni-components-platform-react';
import { WniTableRowUtil } from 'wni-portals-util-react';
import { DatatableUtil } from '@xengage/gw-portals-util-js';
import { CUUnderlyingService } from 'wni-capability-quoteandbind-cu';
import { ConfigUtil, WindowUtil } from 'wni-portals-util-js';
import metadata from './UnderlyingPolicy.metadata.json5';
import FetchExistingPoliciesPopup from '../Components/FetchExistingPolicies/FetchExistingPoliciesPopup';
import messages from '../CUUnderlyingPage.messages';
import UnderlyingPolicyDetails from '../Components/UnderlyingPolicyDetails/UnderlyingPolicyDetails';
import { VALIDATION_ICON_MAP } from '../CUUnderlyingPage.util';

function UnderlyingPolicy(props) {
    const modalApi = useWniModal();
    const {
        id,
        submissionVM,
        UNDERLYING_PATH,
        basePath,
        title,
        isInternal,
        updateShowDetails,
        updateWizardData,
        updateWizardSnapshot,
        resetWizardDataToSnapshot,
        generateValidationIssues,
        showErrors,
        updateShowErrors,
        handleValidation,
        findItemVMValid,
        onValidate = _.noop,
        isReadOnly,
        extendProps,
        extendProps: { accountNumber, jobID, sessionUUID, authHeader } = {},
    } = props;
    const dataPath = `${UNDERLYING_PATH}.${basePath}`;

    const translator = useTranslator();
    const viewModelService = useContext(ViewModelServiceContext);
    const {
        loadingMask: { setLoadingMask },
    } = useDependencies('loadingMask');

    const [currentRow, updateCurrentRow] = useState(null);
    const [selection, updateSelection] = useState([]);

    const highlightRowFn = (vm) => {
        WniTableRowUtil.setTablePublicIDSelected(_.get(vm, 'rowIdPath.value'), id);
    };

    useEffect(() => {
        highlightRowFn(currentRow);
        updateShowDetails((prevData) => {
            return {
                ...prevData,
                [basePath]: !!currentRow,
            };
        });
    }, [currentRow]);

    const sortColumn = (a, b, sortType) => {
        highlightRowFn(currentRow);
        return DatatableUtil[sortType](a, b);
    };

    const updateSubmission = (currentVM) => {
        const rowIdPath = _.get(currentVM.value, 'rowIdPath');
        const allData = _.get(submissionVM.value, dataPath);
        const index = allData.findIndex((item) => item.rowIdPath === rowIdPath); 
        const newSubmissionVM = viewModelService.clone(submissionVM);
        _.set(newSubmissionVM.value, `${dataPath}[${index}]`, currentVM.value);
                
        return newSubmissionVM;
    };

    const updateCurrentRowInteranl = (rowData, updateSubmissionData) => {
        updateCurrentRow(rowData);
        if(!rowData) {
            return false;
        }
    
        if(updateSubmissionData && !isReadOnly) {
            const newSubmissionVM = updateSubmission(rowData);
            updateSubmissionData(newSubmissionVM)
        }
    };
  
    const syncWizardDataSnapshot = (currentVM) => {
        updateCurrentRowInteranl(currentVM, updateWizardSnapshot);
    };
    const syncWizardData = (currentVM) => {
        updateCurrentRowInteranl(currentVM, updateWizardData);
    };

    const syncResponseData = (res) => {
        generateValidationIssues(res.errorsAndWarnings);
        const newSubmissionVM = viewModelService.clone(submissionVM);
        if(!_.isNil(res[basePath])) {
            _.set(newSubmissionVM.value, `${dataPath}`, res[basePath]);
        }
        updateWizardSnapshot(newSubmissionVM);
        updateCurrentRow(null);
        updateShowErrors(false);
    };

    const viewOrEdit = (item, index) => {
        if(!index) {
            return false;
        }
        const childrenVM = _.get(submissionVM, `${dataPath}.children`, []);
        const currIndex = childrenVM.findIndex((itemVM) => _.get(itemVM, 'rowIdPath.value') === index);
        const currentVM = childrenVM[currIndex];
        _.set(currentVM.value, 'isUpdate', true);
        updateCurrentRow(currentVM);
    };

    const removeItems = async() => {
        setLoadingMask(true);
        const res = await CUUnderlyingService.removeItems(jobID, sessionUUID, selection, authHeader);
        syncResponseData(res);
        setLoadingMask(false);
    };

    const addItem = async() => {
        const childrenVM = _.get(submissionVM, `${dataPath}`, []);
        const { _dtoName, _xCenter } = childrenVM;
        const defaultValue = {
            rowIdPath: ConfigUtil.getUuid(),
            isUpdate: true,
        };

        const newVm = viewModelService.create(defaultValue, _xCenter, _dtoName);
        childrenVM.pushElement(newVm);
        updateCurrentRow(newVm);
        updateWizardData(submissionVM);
        updateShowErrors(false);

    };

    const saveAndClose = async() => {
        if(!findItemVMValid(currentRow)) {
            handleValidation()
            return false;
        }
        setLoadingMask(true);
        const res = await CUUnderlyingService.updateUnderlyingPolicy(jobID, sessionUUID, currentRow.value, authHeader);
        syncResponseData(res);
        setLoadingMask(false);
        updateCurrentRow(null);
    };

    const saveAndNext = async() => {
        await saveAndClose();

    }

    const cancelItem = () => {
        updateCurrentRow(null);
        if(!isInternal || !isReadOnly) {
            resetWizardDataToSnapshot();
        }
    };
    const refreshPolicies = async() => {
        setLoadingMask(true);
        const res = await CUUnderlyingService.refreshUnderlyingPolicies(jobID, sessionUUID, authHeader);
        syncResponseData(res);
        setLoadingMask(false);
    };

    const addExistingPolicies = async(policyIds) => {
        setLoadingMask(true);
        const res = await CUUnderlyingService.addInternalPolicies(jobID, sessionUUID, policyIds, authHeader);
        syncResponseData(res);
        setLoadingMask(false);
       
    };

    const fetchPolicies = async(searchAccountNumber) => {
        setLoadingMask(true);
        const res = await CUUnderlyingService.fetchExistingPolicies(jobID, sessionUUID, searchAccountNumber, authHeader);
        setLoadingMask(false);
        return res.fetchPolicies
    };

    const fetchExistingPolicies = async() => {
        const componentProps = {
            title: messages.fetchExistingPolicies,
            size: 'lg',
            accountNumber,
            fetchPolicies
        };

        modalApi.showModal(<FetchExistingPoliciesPopup {...componentProps} />).then(async(res) => {
            await addExistingPolicies(res);
        }).catch(_.noop)
    };

    const renderCell = (item, index, { path }) => {
        return WniTableRowUtil.renderCell(index, item[path]);
    };


    const renderValidationCell = (item, index) => {
        const childrenVM = _.get(submissionVM, `${dataPath}.children`, []);
        const itemVM = childrenVM.find((v) => v.value.rowIdPath === index) || {};
        let type;
        const isItemValid = findItemVMValid(itemVM);
        if(isItemValid) {
            type = 'success';
            
        } else {
            type = 'error';
        }
        return <Icon id={`validationIcon${item.rowIdPath}`} icon={VALIDATION_ICON_MAP[type]} className={`wni-icon-${type}`} />
    };

    const writeValue = (value, path) => {
        const newCurrentRow = viewModelService.clone(currentRow);
        _.set(newCurrentRow.value, path, value);
        syncWizardData(newCurrentRow);

    };

    const overrideProps = {
        '@field': {
            readOnly: isReadOnly || isInternal,
            showRequired: true,
            shouOptional: false,
        },
        sectionTitle: {
            content: translator(title)
        },
        internalTitleAction: {
            visible: isInternal && !isReadOnly
        },
        externalTitleAction: {
            visible: !isInternal && !isReadOnly
        },
        removeAction: {
            disabled: _.isEmpty(selection) || currentRow
        },
        policyTable: {
            id,
            path: dataPath,
            selectionType: (isInternal || isReadOnly) ? 'none' : 'multi',
            onSelectionChange: (rows) => updateSelection(rows),
            selectedRows: selection
        },
        validationIcon: {
            visible: !isInternal
        },
        status: {
            visible: isInternal
        },
        carrier: {
            visible: !isInternal
        },
        viewOrEditLink: {
            label: (isReadOnly || isInternal) ? messages.viewLabel : messages.viewAndEditLabel
        },
        policyDetailsSection: {
            visible: !_.isNil(currentRow)
        },
        actionContainer: {
            justifyContent: (isInternal || isReadOnly) ? 'right' : 'between'
        },
        saveButtons: {
            visible: !isInternal && !isReadOnly
        },
        policyDetailsField: {
            isInternal,
            currentRow,
            onValidate,
            findItemVMValid,
            writeValue,
            syncWizardData,
            handleValidation,
            isReadOnly: isReadOnly || isInternal,
            showErrors,
            extendProps
        }
       
    };

    const resolvers = {
        callbackMap: {
            refreshPolicies,
            fetchExistingPolicies,
            viewOrEdit,
            renderCell,
            renderValidationCell,
            sortString: (a, b) => sortColumn(a, b, 'sortString'),
            sortDate: (a, b) => sortColumn(a, b, 'sortDate'),
            sortNumber: (a, b) => sortColumn(a, b, 'sortNumber'),
            sortCurrency: (a, b) => sortColumn(a, b, 'sortCurrency'),
            removeItems,
            addItem,
            cancelItem,
            saveAndNext,
            saveAndClose
        },
        componentMap: {
            policydetails: UnderlyingPolicyDetails
        },
    };
    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={submissionVM}
            overrideProps={overrideProps}
            onValueChange={writeValue}
            showErrors={showErrors}
            onValidationChange={onValidate}
            {...resolvers}
        />
    );
}

export default UnderlyingPolicy;
