import React, {
    useContext,
    useState,
} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { DisplayColumn } from '@jutro/legacy/datatable';
import { Flex } from '@jutro/layout';
import { Button } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { WniDataTable, WniCheckboxField } from 'wni-common-base-components';
import SingleClauseContext from '../../context/CUSingleClauseContext';
import CoverageConfigContext from '../../context/CUCoveragesConfigContext';
import CUScheduleItemReadonlyCell from './CUScheduleItemReadonlyCell';
import CUScheduleItemEditableCell from './CUScheduleItemEditableCell';
import messages from './CUScheduleTable.messages';
import CUScheduleItemEditableCovTermCell from './CUScheduleItemEditableCovTermCell';

const ColumnType = {
    PropertyInfo: 'PropertyInfo',
    CoveragePropertyInfo: 'CoveragePropertyInfo'
}

const CUScheduleTable = (props) => {
    const {
        schedule,
    } = props

    const {
        propertyInfos = [],
        coveragePropertyInfos = [],
        scheduleItems = [],
        deserializationClass,
        readonly_Ext: scheduleReadonly,
        isComplexSchedule_Ext: isComplexSchedule,
    } = schedule

    const {
        clauseCode: code,
        isEditable,
        clausePath: clauseVMPath,
        onChangeSubmissionAndSync,
    } = useContext(SingleClauseContext)

    const {
        scheduleConfig
    } = useContext(CoverageConfigContext)
 
    const visibleColumnIdsOverride = _.get(scheduleConfig, `visibleColumnIdsOverride.${code}`);
    const columnOrderOverride = _.get(scheduleConfig, `columnOrderOverride.${code}`)

    const [selectedScheduleItemNumbers, setSelectedScheduleItemNumbers] = useState([]);
    const translator = useTranslator();


    const scheduleNumberColumn = <DisplayColumn
        key='ScheduleNumber'
        header= 'Schedule Number'
        sortable = {false}
        textAlign = 'left'
        path = 'itemNumber'
        renderCell = {(scheduleItem) => {
            const {
                itemNumber
            } = scheduleItem
            if (!isEditable || scheduleReadonly) {
                return itemNumber
            }
            return <WniCheckboxField
                value = {selectedScheduleItemNumbers.includes(itemNumber)}
                label = {itemNumber}
                showInlineLabel
                onValueChange = {(checked) => {
                    let newSelectedScheduleItemNumbers
                    if (checked) {
                        newSelectedScheduleItemNumbers = selectedScheduleItemNumbers.concat([itemNumber])
                    } else {
                        newSelectedScheduleItemNumbers = selectedScheduleItemNumbers.filter(num => num !== itemNumber)
                    }
                    setSelectedScheduleItemNumbers(newSelectedScheduleItemNumbers)
                }}
            />
        }}
    />

    const propertyInfoWithWrappers = propertyInfos
        .filter((propertyInfo) => {
            if (propertyInfo.id === 'AdditionalInsured') {
                return false
            }
            if (visibleColumnIdsOverride) {
                return visibleColumnIdsOverride.includes(propertyInfo.id)
            }
            return true
        })
        .map(propertyInfo => ({
            id: propertyInfo.id,
            columnType: ColumnType.PropertyInfo,
            columnInfo: propertyInfo,
            order: propertyInfo.order,
        }))
    
    const coveragePropertyInfoWithWrappers = coveragePropertyInfos
        .filter((coveragePropertyInfo) => {
            if (visibleColumnIdsOverride) {
                return visibleColumnIdsOverride.includes(coveragePropertyInfo.id)
            }
            return true
        })
        .map(coveragePropertyInfo => ({
            id: coveragePropertyInfo.id,
            columnType: ColumnType.CoveragePropertyInfo,
            columnInfo: coveragePropertyInfo,
            order: coveragePropertyInfo.order,
        }))

    const sortedColumn = propertyInfoWithWrappers
        .concat(coveragePropertyInfoWithWrappers)
        .sort((a, b) => {
            if (columnOrderOverride) {
                const aOrder = _.get(columnOrderOverride, `${a.id}`, a.order)
                const bOrder = _.get(columnOrderOverride, `${b.id}`, b.order)
                return aOrder - bOrder
            }
            // By default, all propertyInfo should display before coverageProeprtyInfo
            if (a.columnType !== b.columnType) {
                return a.columnType === ColumnType.PropertyInfo ? -1 : 1
            }
            return a.order - b.order
        })
        .map((infoWithWrapper => {
            if (infoWithWrapper.columnType === ColumnType.PropertyInfo) {
                const {columnInfo: propertyInfo} = infoWithWrapper
                const {
                    id,
                    label,
                } = propertyInfo
                
                return <DisplayColumn
                    key = {id}
                    header = {label}
                    renderCell = {(scheduleItem) => {
                        const {
                            itemNumber
                        } = scheduleItem;
                        const isCellEditable = isEditable && !scheduleReadonly
                        if (isCellEditable) {
                            const scheduleItemIndex = scheduleItems.findIndex((item) => {
                                return item.itemNumber === itemNumber
                            })
                            const scheduleItemVMPath = `${clauseVMPath}.schedule.scheduleItems.children[${scheduleItemIndex}]`
                            return <CUScheduleItemEditableCell
                                propertyInfo={propertyInfo}
                                scheduleItem={scheduleItem}
                                scheduleItemIndex={scheduleItemIndex}
                                scheduleItemVMPath={scheduleItemVMPath}
                            />
                        }
                        return <CUScheduleItemReadonlyCell
                            propertyInfo={propertyInfo}
                            scheduleItem={scheduleItem}
                        />
                    }}
                    sortable = {false}
                />
            } 
            if (infoWithWrapper.columnType === ColumnType.CoveragePropertyInfo) {

                const {columnInfo: coveragePropertyInfo} = infoWithWrapper

                const {
                    id,
                    label,
                } = coveragePropertyInfo
                
                return <DisplayColumn
                    key = {id}
                    header = {label}
                    renderCell = {(scheduleItem) => {
                        const {
                            itemNumber,
                            scheduleItemTerms = []
                        } = scheduleItem
                        const term = scheduleItemTerms.find(t => t.code_Ext === id);
                        if (_.isNil(term)) {
                            return null;
                        }
                        
                        const isCellEditable = isEditable && !scheduleReadonly
    
                        if (isCellEditable) {
                            const scheduleItemIndex = scheduleItems.findIndex((item) => {
                                return item.itemNumber === itemNumber
                            })
                            const scheduleItemPath = `${clauseVMPath}.schedule.scheduleItems.[${scheduleItemIndex}]`
                            return <CUScheduleItemEditableCovTermCell
                                term={term}
                                scheduleItem={scheduleItem}
                                scheduleItemPath={scheduleItemPath}
                                scheduleItemIndex={scheduleItemIndex}
                            />
                        }
                        return term.chosenTermValue
                    }}
                    sortable = {false}
                />
            }

            return null
        }))
        
    
    
    const sortedScheduleItems = scheduleItems.toSorted((scheduleItemA, scheduleItemB) => {
        const getScheduleNumber = (scheduleItem) => {
            return _.get(scheduleItem, 'itemNumber')
        }
        return getScheduleNumber(scheduleItemA) - getScheduleNumber(scheduleItemB)
    })

    const delSchedule = () => {
        const newScheduleItems = scheduleItems.filter((scheduleItem) => {
            const {
                itemNumber
            } = scheduleItem
            return !selectedScheduleItemNumbers.includes(itemNumber)
        })
        onChangeSubmissionAndSync(newScheduleItems, `${clauseVMPath}.schedule.scheduleItems`)
        setSelectedScheduleItemNumbers([])
    }

    const addSchedule = () => {
        const newScheduleItems = scheduleItems.concat([{
            '@deserialization-class': deserializationClass,
            itemData: {
            }
        }])
        onChangeSubmissionAndSync(newScheduleItems, `${clauseVMPath}.schedule.scheduleItems`)
    }

    const columns = [scheduleNumberColumn, ...sortedColumn];

    return <>
        {
            isEditable && !scheduleReadonly && <Flex
                gap = "small"
                justifyContent = "right"
                className = "mb-10"
            >
                <Button
                        className ="wni-button-danger"
                        disabled = {selectedScheduleItemNumbers.length === 0}
                        onClick = {delSchedule}
                        label={messages.scheduleDel}
                />
                <Button
                        icon ="gw-add"
                        onClick = {addSchedule}
                        label={messages.scheduleAdd}
                />
            </Flex>
        }
        <div className='table-wrapper mb-10'>
            <WniDataTable
                id = {`clause_schedule_table_${code}`}
                data={sortedScheduleItems}
                showSearch={false}
            >
                {sortedScheduleItems.length > 0 ? columns : []}
            </WniDataTable>
        </div>
    </>
}

CUScheduleTable.propTypes = {
    schedule: PropTypes.shape({
        propertyInfos: PropTypes.arrayOf(PropTypes.shape({}))
    }).isRequired,
    showCovTermPropertyInfo: PropTypes.bool
}

CUScheduleTable.defaultProps = {
    schedule: {
        propertyInfos: []
    },
    showCovTermPropertyInfo: false
}

export default CUScheduleTable