import { defineMessages } from 'react-intl';

export default defineMessages({
    Close: {
        id: 'wni.quoteandbind.cp-wizard.cp-riskPage.RiskComponent.Close',
        defaultMessage: 'Close',
    },
    CalllValuation: {
        id: 'wni.quoteandbind.cp-wizard.cp-riskPage.RiskComponent.Call valuation',
        defaultMessage: 'Call Valuation',
    },
    UpdateValuation: {
        id: 'wni.quoteandbind.cp-wizard.cp-riskPage.RiskComponent.Update valuation',
        defaultMessage: 'Update Valuation',
    },
    BR_CL_0365: {
        id: 'wni.quoteandbind.cp-wizard.cp-riskPage.RiskComponent.BR_CL_0365',
        defaultMessage: 'Cannot run RCE as {requiredFields} is missing or incorrect.',
    },
});