import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('cpRisksService'), method, data, additionalHeaders);
}

export default class CPRisksService {
    /**
     * Invokes CPRisksHandler.getLocationDetails()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {string} locationPublicID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static getLocationDetails(quoteID, sessionUUID, locationPublicID, authHeader = {}) {
        return processSubmission('getLocationDetails', [quoteID, sessionUUID, locationPublicID], authHeader);
    }

    /**
     * Invokes CPRisksHandler.getBuildingDetails()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {string} locationPublicID
     * @param {string} buildingPublicID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static getBuildingDetails(quoteID, sessionUUID, locationPublicID, buildingPublicID, generateDisplayable, authHeader = {}) {
        return processSubmission('getBuildingDetails', [quoteID, sessionUUID, locationPublicID, buildingPublicID, generateDisplayable], authHeader);
    }

    /**
     * Invokes CPRisksHandler.getSpecialClassDetails()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {string} locationPublicID
     * @param {string} buildingPublicID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static getSpecialClassDetails(quoteID, sessionUUID, locationPublicID, specialClassPublicID, generateDisplayable, authHeader = {}) {
        return processSubmission('getSpecialClassDetails', [quoteID, sessionUUID, locationPublicID, specialClassPublicID, generateDisplayable], authHeader);
    }

    /**
     * Invokes CPRisksHandler.getOccupancyDetails()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {string} locationPublicID
     * @param {string} buildingPublicID
     * @param {string} occupancyPublicID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static getOccupancyDetails(quoteID, sessionUUID, locationPublicID, buildingPublicID, occupancyPublicID, generateDisplayable, authHeader = {}) {
        return processSubmission('getOccupancyDetails', [quoteID, sessionUUID, locationPublicID, buildingPublicID, occupancyPublicID, generateDisplayable], authHeader);
    }

    /**
     * Invokes CPRisksHandler.getBusinessIncomeDetails()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {string} locationPublicID
     * @param {string} buildingPublicID
     * @param {string} businessIncomePublicID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static getBusinessIncomeDetails(quoteID, sessionUUID, locationPublicID, buildingPublicID, businessIncomePublicID, authHeader = {}) {
        return processSubmission('getBusinessIncomeDetails', [quoteID, sessionUUID, locationPublicID, buildingPublicID, businessIncomePublicID], authHeader);
    }

    /**
     * Invokes CPRisksHandler.getSpecialClassBusinessIncomeDetails()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {string} locationPublicID
     * @param {string} specialClassPublicID
     * @param {string} specialClassBusinessIncomePublicID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static getSpecialClassBusinessIncomeDetails(quoteID, sessionUUID, locationPublicID, specialClassPublicID, specialClassBusinessIncomePublicID, authHeader = {}) {
        return processSubmission('getSpecialClassBusinessIncomeDetails', [quoteID, sessionUUID, locationPublicID, specialClassPublicID, specialClassBusinessIncomePublicID], authHeader);
    }

    /**
     * Invokes CPRisksHandler.getPersonalPropertyDetails()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {string} locationPublicID
     * @param {string} buildingPublicID
     * @param {string} occupancyPublicID
     * @param {string} personalPropertyPublicID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static getPersonalPropertyDetails(quoteID, sessionUUID, locationPublicID, buildingPublicID, occupancyPublicID, personalPropertyPublicID, authHeader = {}) {
        return processSubmission('getPersonalPropertyDetails', [quoteID, sessionUUID, locationPublicID, buildingPublicID, occupancyPublicID, personalPropertyPublicID], authHeader);
    }

    /**
     * @function
     * @param {string} jobID 
     * @param {string} sessionUUID 
     * @param {string} locationPublicID
     * @param {string} buildingPublicID
     * @param {Object} authHeader
     * @returns {Promise}
     */
    static getBuildingClauses(jobID , sessionUUID, locationPublicID, buildingPublicID, authHeader = {}) {
        return processSubmission(
            'getBuildingClauses',
            [jobID, sessionUUID, locationPublicID, buildingPublicID],
            authHeader
        );
    }

    /**
     * @function
     * @param {string} jobID 
     * @param {string} sessionUUID 
     * @param {string} locationPublicID
     * @param {string} buildingPublicID
     * @param {Object} dto
     * @param {Object} authHeader
     * @returns {Promise}
     */
    static updateBuildingClauses(jobID , sessionUUID, locationPublicID, buildingPublicID, dto, authHeader = {}) {
        return processSubmission(
            'updateBuildingClauses',
            [jobID, sessionUUID, locationPublicID, buildingPublicID, dto],
            authHeader
        );
    }

    /**
     * @function
     * @param {string} jobID 
     * @param {string} sessionUUID 
     * @param {string} locationPublicID
     * @param {string} buildingPublicID
     * @param {string} occupancyClassPublicID
     * @param {Object} authHeader
     * @returns {Promise}
     */
    static getOccupancyClassClauses(jobID , sessionUUID, locationPublicID, buildingPublicID, occupancyClassPublicID, authHeader = {}) {
        return processSubmission(
            'getOccupancyClassClauses',
            [jobID, sessionUUID, locationPublicID, buildingPublicID, occupancyClassPublicID],
            authHeader
        );
    }

    /**
     * @function
     * @param {string} jobID 
     * @param {string} sessionUUID 
     * @param {string} locationPublicID
     * @param {string} buildingPublicID
     * @param {string} occupancyClassPublicID
     * @param {Object} dto
     * @param {Object} authHeader
     * @returns {Promise}
     */
    static updateOccupancyClassClauses(jobID , sessionUUID, locationPublicID, buildingPublicID, occupancyClassPublicID, dto, authHeader = {}) {
        return processSubmission(
            'updateOccupancyClassClauses',
            [jobID, sessionUUID, locationPublicID, buildingPublicID, occupancyClassPublicID, dto],
            authHeader
        );
    }

    /**
     * @function
     * @param {string} jobID 
     * @param {string} sessionUUID 
     * @param {string} locationPublicID
     * @param {string} buildingPublicID
     * @param {string} occupancyClassPublicID
     * @param {string} personalPropertyPublicID
     * @param {Object} authHeader
     * @returns {Promise}
     */
    static getPersonalPropertyClauses(jobID , sessionUUID, locationPublicID, buildingPublicID, occupancyClassPublicID, personalPropertyPublicID, authHeader = {}) {
        return processSubmission(
            'getPersonalPropertyClauses',
            [jobID, sessionUUID, locationPublicID, buildingPublicID, occupancyClassPublicID, personalPropertyPublicID],
            authHeader
        );
    }

    /**
     * @function
     * @param {string} jobID 
     * @param {string} sessionUUID 
     * @param {string} locationPublicID
     * @param {string} buildingPublicID
     * @param {string} occupancyClassPublicID
     * @param {string} personalPropertyPublicID
     * @param {Object} dto
     * @param {Object} authHeader
     * @returns {Promise}
     */
    static updatePersonalPropertyClauses(jobID , sessionUUID, locationPublicID, buildingPublicID, occupancyClassPublicID, personalPropertyPublicID, dto, authHeader = {}) {
        return processSubmission(
            'updatePersonalPropertyClauses',
            [jobID, sessionUUID, locationPublicID, buildingPublicID, occupancyClassPublicID, personalPropertyPublicID, dto],
            authHeader
        );
    }

    /**
     * @function
     * @param {string} jobID 
     * @param {string} sessionUUID 
     * @param {string} locationPublicID
     * @param {string} buildingPublicID
     * @param {string} businessIncomePublicID
     * @param {Object} authHeader
     * @returns {Promise}
     */
    static getBusinessIncomeClauses(jobID , sessionUUID, locationPublicID, buildingPublicID, businessIncomePublicID, authHeader = {}) {
        return processSubmission(
            'getBusinessIncomeClauses',
            [jobID, sessionUUID, locationPublicID, buildingPublicID, businessIncomePublicID],
            authHeader
        );
    }

    /**
     * @function
     * @param {string} jobID 
     * @param {string} sessionUUID 
     * @param {string} locationPublicID
     * @param {string} buildingPublicID
     * @param {string} businessIncomePublicID
     * @param {Object} dto
     * @param {Object} authHeader
     * @returns {Promise}
     */
    static updateBusinessIncomeClauses(jobID , sessionUUID, locationPublicID, buildingPublicID, businessIncomePublicID, dto, authHeader = {}) {
        return processSubmission(
            'updateBusinessIncomeClauses',
            [jobID, sessionUUID, locationPublicID, buildingPublicID, businessIncomePublicID, dto],
            authHeader
        );
    }

    /**
     * @function
     * @param {string} jobID 
     * @param {string} sessionUUID 
     * @param {string} locationPublicID
     * @param {string} specialClassPublicID
     * @param {Object} authHeader
     * @returns {Promise}
     */
    static getSpecialClassClauses(jobID , sessionUUID, locationPublicID, specialClassPublicID, authHeader = {}) {
        return processSubmission(
            'getSpecialClassClauses',
            [jobID, sessionUUID, locationPublicID, specialClassPublicID],
            authHeader
        );
    }

    /**
     * @function
     * @param {string} jobID 
     * @param {string} sessionUUID 
     * @param {string} locationPublicID
     * @param {string} specialClassPublicID
     * @param {Object} dto
     * @param {Object} authHeader
     * @returns {Promise}
     */
    static updateSpecialClassClauses(jobID , sessionUUID, locationPublicID, specialClassPublicID, dto, authHeader = {}) {
        return processSubmission(
            'updateSpecialClassClauses',
            [jobID, sessionUUID, locationPublicID, specialClassPublicID, dto],
            authHeader
        );
    }

    /**
     * @function
     * @param {string} jobID 
     * @param {string} sessionUUID 
     * @param {string} locationPublicID
     * @param {string} specialClassPublicID
     * @param {string} specClsBIPublicID
     * @param {Object} authHeader
     * @returns {Promise}
     */
    static getSpecClsBIClauses(jobID , sessionUUID, locationPublicID, specialClassPublicID, specClsBIPublicID, authHeader = {}) {
        return processSubmission(
            'getSpecClsBIClauses',
            [jobID, sessionUUID, locationPublicID, specialClassPublicID, specClsBIPublicID],
            authHeader
        );
    }

    /**
     * @function
     * @param {string} jobID 
     * @param {string} sessionUUID 
     * @param {string} locationPublicID
     * @param {string} specialClassPublicID
     * @param {string} specClsBIPublicID
     * @param {Object} dto
     * @param {Object} authHeader
     * @returns {Promise}
     */
    static updateSpecClsBIClauses(jobID , sessionUUID, locationPublicID, specialClassPublicID, specClsBIPublicID, dto, authHeader = {}) {
        return processSubmission(
            'updateSpecClsBIClauses',
            [jobID, sessionUUID, locationPublicID, specialClassPublicID, specClsBIPublicID, dto],
            authHeader
        );
    }


    /**
     * Invokes CPRisksHandler.addLocationGroupCoverable()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {string} locationPublicID
     * @param {string} coverableType
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static addLocationGroupCoverable(quoteID, sessionUUID, locationPublicID, coverableType, authHeader = {}) {
        return processSubmission('addLocationGroupCoverable', [quoteID, sessionUUID, locationPublicID, coverableType], authHeader);
    }

    /**
     * Invokes CPRisksHandler.saveSelectedBuildingOccupancyInfo()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {string} locationPublicID
     * @param {string} buildingPublicID
     * @param {Object} selectedBuilding
     * @param {Object} selectedOccupancy
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static saveSelectedBuildingOccupancyInfo(quoteID, sessionUUID, locationPublicID, buildingPublicID, selectedBuilding, selectedOccupancy, authHeader = {}) {
        return processSubmission('saveSelectedBuildingOccupancyInfo', [quoteID, sessionUUID, locationPublicID, buildingPublicID, selectedBuilding, selectedOccupancy], authHeader);
    }

    /**
     * Invokes CPRisksHandler.addBuildingGroupCoverable()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {string} locationPublicID
     * @param {string} buildingPublicID
     * @param {string} coverableType
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static addBuildingGroupCoverable(quoteID, sessionUUID, locationPublicID, buildingPublicID, coverableType, authHeader = {}) {
        return processSubmission('addBuildingGroupCoverable', [quoteID, sessionUUID, locationPublicID, buildingPublicID, coverableType], authHeader);
    }

    /**
     * Invokes CPRisksHandler.addPersonalProperty()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {string} locationPublicID
     * @param {string} buildingPublicID
     * @param {string} occupancyPublicID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static addPersonalProperty(quoteID, sessionUUID, locationPublicID, buildingPublicID, occupancyPublicID, authHeader = {}) {
        return processSubmission('addPersonalProperty', [quoteID, sessionUUID, locationPublicID, buildingPublicID, occupancyPublicID], authHeader);
    }

    /**
     * Invokes CPRisksHandler.addSpecialClassBusinessIncome()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {string} locationPublicID
     * @param {string} specialClassPublicID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static addSpecialClassBusinessIncome(quoteID, sessionUUID, locationPublicID, specialClassPublicID, authHeader = {}) {
        return processSubmission('addSpecialClassBusinessIncome', [quoteID, sessionUUID, locationPublicID, specialClassPublicID], authHeader);
    }

    /**
     * Invokes CPRisksHandler.removeBuilding()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {string} locationPublicID
     * @param {string} buildingPublicID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static removeBuilding(quoteID, sessionUUID, locationPublicID, buildingPublicID, authHeader = {}) {
        return processSubmission('removeBuilding', [quoteID, sessionUUID, locationPublicID, buildingPublicID], authHeader);
    }

    /**
     * Invokes CPRisksHandler.removeSpecialClass()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {string} locationPublicID
     * @param {string} specialClassPublicID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static removeSpecialClass(quoteID, sessionUUID, locationPublicID, specialClassPublicID, authHeader = {}) {
        return processSubmission('removeSpecialClass', [quoteID, sessionUUID, locationPublicID, specialClassPublicID], authHeader);
    }

    /**
     * Invokes CPRisksHandler.removeOccupancy()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {string} locationPublicID
     * @param {string} buildingPublicID
     * @param {string} occupancyPublicID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static removeOccupancy(quoteID, sessionUUID, locationPublicID, buildingPublicID, occupancyPublicID, authHeader = {}) {
        return processSubmission('removeOccupancy', [quoteID, sessionUUID, locationPublicID, buildingPublicID, occupancyPublicID], authHeader);
    }

    /**
     * Invokes CPRisksHandler.removeBusinessIncome()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {string} locationPublicID
     * @param {string} buildingPublicID
     * @param {string} businessIncomePublicID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static removeBusinessIncome(quoteID, sessionUUID, locationPublicID, buildingPublicID, businessIncomePublicID, authHeader = {}) {
        return processSubmission('removeBusinessIncome', [quoteID, sessionUUID, locationPublicID, buildingPublicID, businessIncomePublicID], authHeader);
    }

    /**
     * Invokes CPRisksHandler.removePersonalProperty()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {string} locationPublicID
     * @param {string} buildingPublicID
     * @param {string} occupancyPublicID
     * @param {string} personalPropertyPublicID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static removePersonalProperty(quoteID, sessionUUID, locationPublicID, buildingPublicID, occupancyPublicID, personalPropertyPublicID, authHeader = {}) {
        return processSubmission('removePersonalProperty', [quoteID, sessionUUID, locationPublicID, buildingPublicID, occupancyPublicID, personalPropertyPublicID], authHeader);
    }

    /**
     * Invokes CPRisksHandler.removeSpecialClassBusinessIncome()
     * 
     * @param {string} quoteID 
     * @param {string} sessionUUID 
     * @param {string} locationPublicID
     * @param {string} specialClassPublicID
     * @param {string} specialClassBusinessIncomePublicID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static removeSpecialClassBusinessIncome(quoteID, sessionUUID, locationPublicID, specialClassPublicID, specialClassBusinessIncomePublicID, authHeader = {}) {
        return processSubmission('removeSpecialClassBusinessIncome', [quoteID, sessionUUID, locationPublicID, specialClassPublicID, specialClassBusinessIncomePublicID], authHeader);
    }



    /**
     * @function
     * @param {string} jobID 
     * @param {string} sessionUUID 
     * @param {string} locationPublicID
     * @param {string} buildingPublicID
     * @param {Object} dto
     * @param {Object} authHeader
     * @returns {Promise}
     */

    static updateBuildingDetails(jobID , sessionUUID, locationPublicID, buildingPublicID, dto, authHeader = {}) {
        return processSubmission(
            'updateBuildingDetails',
            [jobID, sessionUUID, locationPublicID, buildingPublicID, dto],
            authHeader
        );
    }

    
    /**
     * @function
     * @param {string} jobID 
     * @param {string} sessionUUID 
     * @param {string} locationPublicID
     * @param {string} buildingPublicID
     * @param {Object} dto
     * @param {Object} authHeader
     * @returns {Promise}
     */

    static updateBuildingDescription(jobID, sessionUUID, locationPublicID, buildingPublicID, dto, authHeader = {}) {
        return processSubmission(
            'updateBuildingDescription',
            [jobID, sessionUUID, locationPublicID, buildingPublicID, dto],
            authHeader
        );
    };

        
    /**
     * @function
     * @param {string} jobID 
     * @param {string} sessionUUID 
     * @param {string} locationPublicID
     * @param {string} buildingPublicID
     * @param {Object} authHeader
     * @returns {Promise}
     */

    static addFireProtectionSystem(jobID, sessionUUID, locationPublicID, buildingPublicID, DTO, authHeader = {}) {
        return processSubmission(
            'addFireProtectionSystem',
            [jobID, sessionUUID, locationPublicID, buildingPublicID, DTO],
            authHeader
        );
    };

        /**
     * @function
     * @param {string} jobID 
     * @param {string} sessionUUID 
     * @param {string} locationPublicID
     * @param {string} buildingPublicID
     * @param {int} indexNumber
     * @param {Object} authHeader
     * @returns {Promise}
     */

        static removeFireProtectionSystem(jobID, sessionUUID, locationPublicID, buildingPublicID, indexNumber, authHeader = {}) {
            return processSubmission(
                'removeFireProtectionSystem',
                [jobID, sessionUUID, locationPublicID, buildingPublicID, indexNumber],
                authHeader
            );
        };
    

    /**
     * @function
     * @param {string} jobID 
     * @param {string} sessionUUID 
     * @param {string} locationPublicID
     * @param {string} buildingPublicID
     * @param {string} occupancyPublicID
     * @param {Object} dto
     * @param {Object} authHeader
     * @returns {Promise}
     */

    static updateOccupancyDetails(jobID, sessionUUID, locationPublicID, buildingPublicID, occupancyPublicID, dto, authHeader = {}) {
        return processSubmission(
            'updateOccupancyDetails',
            [jobID, sessionUUID, locationPublicID, buildingPublicID, occupancyPublicID, dto],
            authHeader
        );
    }

    /**
     * @function
     * @param {string} jobID 
     * @param {string} sessionUUID 
     * @param {string} locationPublicID
     * @param {string} buildingPublicID
     * @param {string} businessIncomePublicID
     * @param {Object} dto
     * @param {Object} authHeader
     * @returns {Promise}
     */

    static updateBusinessIncomeDetails(jobID, sessionUUID, locationPublicID, buildingPublicID, businessIncomePublicID, dto, authHeader = {}) {
        return processSubmission(
            'updateBusinessIncomeDetails',
            [jobID, sessionUUID, locationPublicID, buildingPublicID, businessIncomePublicID, dto],
            authHeader
        );
    }

    /**
     * @function
     * @param {string} jobID 
     * @param {string} sessionUUID 
     * @param {string} locationPublicID
     * @param {string} buildingPublicID
     * @param {string} occupancyPublicID
     * @param {string} personalPropertyPublicID
     * @param {Object} dto
     * @param {Object} authHeader
     * @returns {Promise}
     */
    
    static updatePersonalPropertyDetails(jobID, sessionUUID, locationPublicID, buildingPublicID, occupancyPublicID, personalPropertyPublicID, dto, authHeader = {}) {
        return processSubmission(
            'updatePersonalPropertyDetails',
            [jobID, sessionUUID, locationPublicID, buildingPublicID, occupancyPublicID, personalPropertyPublicID, dto],
            authHeader
        );
    }

        /**
     * @function
     * @param {string} jobID 
     * @param {string} sessionUUID 
     * @param {string} locationPublicID
     * @param {string} specialClassPublicID
     * @param {Object} dto
     * @param {Object} authHeader
     * @returns {Promise}
     */

    static updateSpecialClassDetails(jobID, sessionUUID, locationPublicID, specialClassPublicID, dto, authHeader = {}) {
        return processSubmission(
            'updateSpecialClassDetails',
            [jobID, sessionUUID, locationPublicID, specialClassPublicID, dto],
            authHeader
        );
    }
    

    /**
     * @function
     * @param {string} jobID 
     * @param {string} sessionUUID 
     * @param {string} locationPublicID
     * @param {string} specialClassPublicID
     * @param {Object} dto
     * @param {Object} authHeader
     * @returns {Promise}
     */
    static updateSpecialClassBusinessIncomeDetails(jobID, sessionUUID, locationPublicID, specialClassPublicID, specClsBIPublicID, dto, authHeader = {}) {
        return processSubmission(
            'updateSpecialClassBusinessIncomeDetails',
            [jobID, sessionUUID, locationPublicID, specialClassPublicID, specClsBIPublicID, dto],
            authHeader
        );
    }

   

    /**
     *
     * @param {string} quoteID
     * @param {string} sessionUUID
     * @param {Object} authHeader
     * @returns {Promise}
     */
    static fetchRisksRows(quoteID, sessionUUID, authHeader = {}) {
        return processSubmission(
            'fetchRisksRows',
            [quoteID, sessionUUID],
            authHeader
        );
    }

    /**
     *
     * @param {string} quoteID
     * @param {string} sessionUUID
     * @param {string} exportType
     * @param {Object} authHeader
     * @returns {Promise}
     */
    static getExportFormat(quoteID, sessionUUID, exportType, authHeader = {}) {
        return processSubmission(
            'getExportFormat',
            [quoteID, sessionUUID, exportType],
            authHeader
        );
    }

    /**
     *
     * @param {string} jobNumber
     * @param {string} sessionUUID
     * @param {string} locationPublicID
     * @param {string} buildingPublicID
     * @param {string} searchDTO
     * @param {Object} authHeader
     * @returns {Promise}
     */
    static searchAddressBook(jobNumber, sessionUUID, locationPublicID, buildingPublicID, searchDTO, authHeader = {}) {
        return processSubmission(
            'searchAddressBook',
            [jobNumber, sessionUUID, locationPublicID, buildingPublicID, searchDTO],
            authHeader
        );
    }
    
    /**
     *
     * @param {string} jobNumber
     * @param {string} sessionUUID
     * @param {string} locationPublicID
     * @param {string} buildingPublicID
     * @param {string} searchDTO
     * @param {string} selectedContactResultId
     * @param {Object} mortgageeDetails
     * @param {Object} authHeader
     * @returns {Promise}
     */
    static addMortgageeByAddressBookSearchResultWhileUpdate(
        jobNumber,
        sessionUUID,
        locationPublicID,
        buildingPublicID,
        searchDTO,
        selectedContactResultId,
        mortgageeDetails,
        authHeader = {}
    ) {
        return processSubmission(
            'addMortgageeByAddressBookSearchResultWhileUpdate',
            [jobNumber, sessionUUID, locationPublicID, buildingPublicID, searchDTO, selectedContactResultId, mortgageeDetails],
            authHeader
        );
    }

    /**
     *
     * @param {string} jobNumber
     * @param {string} sessionUUID
     * @param {string} locationPublicID
     * @param {string} buildingPublicID
     * @param {Object} contactDTO
     * @param {Object} mortgageeDetails
     * @param {Object} authHeader
     * @returns {Promise}
     */
    static addNewContactAsMortgageeAdditionalInterestWhileUpdate(
        jobNumber,
        sessionUUID,
        locationPublicID,
        buildingPublicID,
        contactDTO,
        mortgageeDetails,
        authHeader = {}
    ) {
        return processSubmission(
            'addNewContactAsMortgageeAdditionalInterestWhileUpdate',
            [jobNumber, sessionUUID, locationPublicID, buildingPublicID, contactDTO, mortgageeDetails],
            authHeader
        );
    }

    /**
     *
     * @param {string} jobNumber
     * @param {string} sessionUUID
     * @param {string} locationPublicID
     * @param {string} buildingPublicID
     * @param {Object} contactDTO
     * @param {Object} authHeader
     * @returns {Promise}
     */
    static createOrUpdateMortgageeAdditionalInterest(
        jobNumber,
        sessionUUID,
        locationPublicID,
        buildingPublicID,
        contactDTO,
        authHeader = {}
    ) {
        return processSubmission(
            'createOrUpdateMortgageeAdditionalInterest',
            [jobNumber, sessionUUID, locationPublicID, buildingPublicID, contactDTO],
            authHeader
        );
    }

    /**
     *
     * @param {string} jobNumber
     * @param {string} sessionUUID
     * @param {string} locationPublicID
     * @param {string} buildingPublicID
     * @param {array} fixedIDs
     * @param {Object} authHeader
     * @returns {Promise}
     */
    static removeMortgageeAdditionalInterests(
        jobNumber,
        sessionUUID,
        locationPublicID,
        buildingPublicID,
        fixedIDs,
        authHeader = {}
    ) {
        return processSubmission(
            'removeMortgageeAdditionalInterests',
            [jobNumber, sessionUUID, locationPublicID, buildingPublicID, fixedIDs],
            authHeader
        );
    }

    /**
     * @function
     * @param {string} jobID 
     * @param {string} sessionUUID 
     * @param {string} locationPublicID
     * @param {string} buildingPublicID
     * @param {Object} dto
     * @param {boolean} updateValuation
     * @param {Object} authHeader
     * @returns {Promise}
     */

    static callValuation(jobID , sessionUUID, locationPublicID, buildingPublicID, dto, updateValuation, authHeader = {}) {
        return processSubmission(
            'callValuation',
            [jobID, sessionUUID, locationPublicID, buildingPublicID, dto, updateValuation],
            authHeader
        );
    }
}