import { useTranslator } from '@jutro/locale';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import _ from 'lodash';
import React, { useContext, useMemo } from 'react';
import { useWniModal } from 'wni-components-platform-react';
import metadata from './UnderlyingPolicyDetails.metadata.json5';
import AircraftDetails from '../Details/AircraftDetails/AircraftDetails';
import BOPDetails from '../Details/BOPDetails/BOPDetails';
import CommAutoDetails from '../Details/CommAutoDetails/CommAutoDetails';
import CommercialFarmDetails from '../Details/CommercialFarmDetails/CommercialFarmDetails';
import CyberDetails from '../Details/CyberDetails/CyberDetails';
import EmpBenLiabDetails from '../Details/EmpBenLiabDetails/EmpBenLiabDetails';
import EmpLiabDetails from '../Details/EmpLiabDetails/EmpLiabDetails';
import GenLiabDetails from '../Details/GenLiabDetails/GenLiabDetails';
import LiquorDetails from '../Details/LiquorDetails/LiquorDetails';
import MotorCarrierLiabilityDetails from '../Details/MotorCarrierLiabilityDetails/MotorCarrierLiabilityDetails';
import OtherDetails from '../Details/OtherDetails/OtherDetails';
import UnmannedAircraftDetails from '../Details/UnmannedAircraftDetails/UnmannedAircraftDetails';
import WatercraftDetails from '../Details/WatercraftDetails/WatercraftDetails';
import WorkCompDetails from '../Details/WorkCompDetails/WorkCompDetails';
import ProfessionalLiabilityDetails from '../Details/ProfessionalLiabilityDetails/ProfessionalLiabilityDetails';

const DefaultDetails = (props) => {
    return null;
};

function UnderlyingPolicyDetails(props) { 
    const {
        currentRow,
        writeValue,
        syncWizardData,
        onValidate,
        showErrors,
        isReadOnly,
        handleValidation,
    } = props;

    if(!currentRow) {
        return null;
    };

    const {
        policyType
    } = currentRow.value;

    const detailsField = useMemo(() => {
        switch (policyType) {
            case 'Aircraft':
                return AircraftDetails;
            case 'UnmannedAircraft_Ext':
                return UnmannedAircraftDetails;
            case 'BOP':
                return BOPDetails;
            case 'CommAuto':
                return CommAutoDetails;
            case 'CommercialFarm':
                return CommercialFarmDetails;
            case 'Cyber':
                return CyberDetails;
            case 'EmpBenLiab':
                return EmpBenLiabDetails;
            case 'EmpLiab':
                return EmpLiabDetails;
            case 'GenLiab':
                return GenLiabDetails;
            case 'Liquor':
                return LiquorDetails;
            case 'Watercraft':
                return WatercraftDetails;
            case 'WorkComp':
                return WorkCompDetails;
            case 'MotorCarrierLiability_Ext':
                return MotorCarrierLiabilityDetails;
            case 'ProfessionalLiability_Ext':
                return ProfessionalLiabilityDetails;
            case 'Other_Ext':
                return OtherDetails;
            default:
                return DefaultDetails;
        }
    }, [policyType]);
    const overrideProps = {
        '@field': {
            readOnly: isReadOnly,
            showRequired: true,
            shouOptional: false
        },
        policyDetails: {
            visible: !_.isNil(policyType),
            ...props
        },
        premium: {
            visible: policyType !== 'ProfessionalLiability_Ext'
        },
        glProLiaPremium: {
            visible: policyType === 'ProfessionalLiability_Ext'
        }
       
    };

    const resolvers = {
        callbackMap: {},
        componentMap: {
            detailsField
        },
    };
    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={currentRow}
            overrideProps={overrideProps}
            onValueChange={writeValue}
            showErrors={showErrors}
            onValidationChange={onValidate}
            {...resolvers}
        />
    );
}

export default UnderlyingPolicyDetails;
