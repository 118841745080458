import {
    ViewModelForm,
} from '@xengage/gw-portals-viewmodel-react';
import _ from 'lodash';
import React, { useContext } from 'react';
import {
    generateCommAutoFieldsVisible,
    generateFieldsVisible,
    isFieldVisible,
} from '../../../CUUnderlyingPage.util';
import metadata from './CommAutoDetails.metadata.json5';

function CommAutoDetails(props) {
    const {
        currentRow,
        syncWizardData,
        writeValue,
        onValidate,
        showErrors,
        isReadOnly,
    } = props;

    const isVisible = (path) => {
        return isFieldVisible(currentRow, path);
    };

    const onValueChange = (value, path) => {
        switch(path) {
            case 'caUnderlyingPolType':
                _.set(currentRow.value, path, value);
                _.set(currentRow.value, 'caLiabilityCoverageType', undefined);
                break;
            default:
                _.set(currentRow.value, path, value);
                break
        }
        syncWizardData(currentRow);
    };

    const overrideProps = {
        '@field': {
            readOnly: isReadOnly,
            showRequired: true,
            shouOptional: false,
        },
        caUnderlyingPolType: {
            onValueChange,
            visible: isVisible('caUnderlyingPolType')
        },
        caLiabilityCoverageType: {
            visible: isVisible('caLiabilityCoverageType'),
        },
        adEachAccAutoOnly: {
            visible: isVisible('adEachAccAutoOnly'),
        },
        adEachAccOtherAuto: {
            visible: isVisible('adEachAccOtherAuto'),
        },
        adAggLimOtherAuto: {
            visible: isVisible('adAggLimOtherAuto'),
        },
        cabiEachAcc: {
            visible: isVisible('cabiEachAcc'),
        },
        cabiEachPerson: {
            visible: isVisible('cabiEachPerson'),
        },
        capdEachAcc: {
            visible: isVisible('capdEachAcc'),
        },
        caCombSingLimiType: {
            visible: isVisible('caCombSingLimiType'),
        },
        caOthGenLiaAggType: {
            visible: isVisible('caOthGenLiaAggType'),
        },
        caProAndWorYouPerAggType: {
            visible: isVisible('caProAndWorYouPerAggType'),
        },
        adLiabilityPremium: {
            visible: isVisible('adLiabilityPremium')
        },
        adAEOOLiabilityPremium: {
            visible: isVisible('adAEOOLiabilityPremium')
        },
        ...generateFieldsVisible(currentRow),
        ...generateCommAutoFieldsVisible(currentRow),
    };

    const resolvers = {
        callbackMap: {},
        componentMap: {},
    };
    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={currentRow}
            overrideProps={overrideProps}
            onValueChange={writeValue}
            showErrors={showErrors}
            onValidationChange={onValidate}
            {...resolvers}
        />
    );
}

export default CommAutoDetails;
