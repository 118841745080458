import React, {useState} from "react"
import _ from "lodash";
import { Button } from "@jutro/components";
import { WniDataTable, WniToggle } from "wni-common-base-components";
import { DisplayColumn } from '@jutro/legacy/datatable';
import { Flex,} from '@jutro/layout';
import { useTranslator } from '@jutro/locale';
import { ProductUtil } from 'wni-portals-util-react';
import SinglePedestrian from "./SinglePedestrian/SinglePedestrian";
import messages from './Pedestrians.messages'

const PedestrianQuestionValue = {
    Yes: 'Yes',
    No: 'No'
}

const AddNewPerson = 'AddNewPerson'

const {
    GL_LOB_NAME
} = ProductUtil;

const Pedestrians = (props) => {
    const {
        pedestrianIncidents = [],
        updatePedestrianIncidents,
        relatedContacts = [],
        savePedestrianIncident,
        removePedestrianIncident,
        LOB_NAME
    } = props;

    const translator = useTranslator();

    const [pedestrianQuestionValue, setPedestrianQuestion] = useState(() => {
        return pedestrianIncidents.length > 0 ? PedestrianQuestionValue.Yes : PedestrianQuestionValue.No
    })
    const [singlePedestrianPopupStatus, setSinglePedestrianPopupStatus] = useState({
        isOpen: false,
        originPedestrianIncident: {},
        editingPedestrianIndex: undefined
    })
    const [selectedPedestriansIndexs, setSelectedPedestriansIndexs] = useState([])
    const closePopup = () => {
        setSinglePedestrianPopupStatus({
            isOpen: false,
            originPedestrianIncident: {},
            editingPedestrianIndex: undefined
        })
    }

    const onPopupCancel = () => {
        closePopup()
    }

    const onPopupSave = async (pedestrianIncident) => {
        await savePedestrianIncident(pedestrianIncident)
        closePopup()
    }

    return <>
        <WniToggle
            id='pedestrianQuestion'
            label={translator(messages.PedestrianQuestion)}
            labelPosition='left'
            availableValues={[
                {
                    code: PedestrianQuestionValue.Yes,
                    name: translator(messages.Yes)
                },
                {
                    code: PedestrianQuestionValue.No,
                    name: translator(messages.No)
                }
            ]}
            onValueChange={(value) => {
                setPedestrianQuestion(value)
            }}
            disabled={pedestrianIncidents.length > 0}
            value={pedestrianQuestionValue}
        />
        {pedestrianQuestionValue === PedestrianQuestionValue.Yes &&
            <>
                <Flex id="injuryFlexButton" justifyContent="right" className="mb-10">
                    <Button
                        variant="primary"
                        icon="gw-add"
                        onClick={() => setSinglePedestrianPopupStatus({
                            isOpen: true,
                            originPedestrianIncident: {
                                injuried: {
                                    personPublicID: AddNewPerson
                                },
                                subtype: 'Person',
                                primaryAddress: {
                                    country: 'US'
                                }
                            }
                        })}
                        disabled={singlePedestrianPopupStatus.isOpen}
                        label={translator(messages.AddPedestrian)}
                    />
                    <Button
                        type="primary"
                        className="wni-button-danger"
                        onClick={() => {
                            const newPedestrianIncidents = pedestrianIncidents.filter((_incident, index) => {
                                if (selectedPedestriansIndexs.includes(index)) {
                                    removePedestrianIncident(pedestrianIncidents[index])
                                    return false
                                }
                                return true
                            })
                            updatePedestrianIncidents(newPedestrianIncidents)
                        }}
                        disabled={_.isEmpty(selectedPedestriansIndexs)}
                        label={translator(messages.DeletePedestrian)}
                    />
                </Flex>
                <WniDataTable
                    id="pedestrianIncidentsTable"
                    showSearch= {false}
                    showPagination= {false}
                    data={pedestrianIncidents.map((e, index) => ({...e, number: index + 1}))}
                    titlePosition='left'
                    rowIdpath="number"
                    selectionType="multi"
                    onSelectionChange={(selectedIndexs) => setSelectedPedestriansIndexs(selectedIndexs)}
                    selectedRows={selectedPedestriansIndexs}
                >
                    <DisplayColumn
                        id="pedestrianSectionIDColumn"
                        header={translator(messages.Number)}
                        path="number"
                        textAlign='center'
                    />
                    <DisplayColumn
                        id="pedestrianDescriptionColumn"
                        header={translator(messages.PedestrianDescription)}
                        path="description"
                        textAlign='center'
                        visible={LOB_NAME === GL_LOB_NAME}
                    />
                    <DisplayColumn
                        id="pedestrianNameColumn"
                        header={translator(messages.PedestrianName)}
                        path="injuried.displayName"
                        textAlign='center'
                        visible={LOB_NAME === GL_LOB_NAME}
                    />
                    <DisplayColumn
                        id="pedestrianLastNameColumn"
                        header={translator(messages.PedestrianLastName)}
                        textAlign='center'
                        renderCell={(item) => {
                            const {
                                injuried: {
                                    publicID
                                }
                            } = item
                            const pedestrian = relatedContacts.find((contact) => contact.publicID === publicID)
                            return _.get(pedestrian, 'lastName')
                        }}
                        visible={LOB_NAME !== GL_LOB_NAME}
                    />
                    <DisplayColumn
                        id="actionColumn"
                        textAlign='right'
                        renderCell={(item, index) => {
                            return <Flex gap="small" justifyContent="right" className="mb-10">
                                <Button
                                    className="btn-link"
                                    onClick={() => 
                                        {
                                            const country = _.get(item, 'injuried.primaryAddress.country', 'US');
                                            _.set(item, 'injuried.primaryAddress.country', country);
                                            setSinglePedestrianPopupStatus({
                                                isOpen: true,
                                                originPedestrianIncident: item,
                                                editingPedestrianIndex: index
                                            }) 
                                        }
                                        }
                                    label={translator(messages.viewAndEditLabel)}
                                />
                            </Flex>
                        }}
                    />
                </WniDataTable>
                {singlePedestrianPopupStatus.isOpen && <SinglePedestrian
                    originPedestrianIncident={singlePedestrianPopupStatus.originPedestrianIncident}
                    editingPedestrianIndex={singlePedestrianPopupStatus.editingPedestrianIndex}
                    onPopupCancel={onPopupCancel}
                    onPopupSave={onPopupSave}
                />}
            </>
        }
        
    </>
}

export default Pedestrians
