import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import React, { useEffect, useState, useCallback } from 'react';
import _ from 'lodash';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useTranslator } from '@jutro/locale';
import { CUUnderlyingService } from 'wni-capability-quoteandbind-cu';
import metadata from './GLExposureDetails.metadata.json5';

function GLExposureDetails(props) {
    const {
        currentRow,
        syncCurrentRow,
        exposureVM,
        writeValue,
        onValidate,
        showErrors,
        isReadOnly,
        extendProps: { jobID, sessionUUID, authHeader }
    } = props;

    const {
        glExpType,
        classCode
    } = exposureVM.value;
    const {
        loadingMask: { setLoadingMask },
    } = useDependencies('loadingMask');
    const translator = useTranslator();
    const [classCodeOptions, updateClassCodeOptions] = useState([]);

    const getGlExpTypeAvailableValues = () => {
        const options = _.get(currentRow, 'glExpTypes.value', []);
        return options.map((item) => {
            return {
                name: translator({ id: `typekey.CELGLExpType.${item}`}),
                code: item
            }
        })
    };

    const getClassCodeValus = async() => {
        if(!glExpType) {
            return []
        };
        setLoadingMask(true);
        const res = await CUUnderlyingService.fetchClassCodes(glExpType, authHeader) || [];
        setLoadingMask(false)
        const classCodes = res.map((item) => {
            return {
                name: item,
                code: item
            }
        });
        updateClassCodeOptions(classCodes);
    };

    useEffect(() => {
        getClassCodeValus()
    }, [glExpType]);

    const handleGlExpTypeChange = async(value, path) => {
        if(value === glExpType) {
            return false;
        };
        _.set(exposureVM.value, path, value);
        _.set(exposureVM.value, 'classCode', null);
        syncCurrentRow(exposureVM);       
    };

    const handleClassCodeChange = async(value, path) => {
        if(value === classCode) {
            return false;
        };
        
        _.set(exposureVM.value, path, value);
        setLoadingMask(true);
        const res = await CUUnderlyingService.postOnChangeForClassCode(value, authHeader);
        _.set(exposureVM.value, 'premOpsIncrdLimitTableAssignment', res.premOpsIncrdLimitTableAssignment);
        _.set(exposureVM.value, 'prodsCompldOpsIncrdLimitTableAssignmen', res.prodsCompldOpsIncrdLimitTableAssignmen);
        syncCurrentRow(exposureVM);
        setLoadingMask(false);
    }

    console.log(exposureVM)
    const overrideProps = {
        '@field': {
            readOnly: isReadOnly,
            showRequired: true,
            shouOptional: false
        },
        GlExpType: {
            availableValues: getGlExpTypeAvailableValues(),
            onValueChange: handleGlExpTypeChange
        },
        classCode: {
            availableValues: classCodeOptions,
            onValueChange: handleClassCodeChange
        },

       
    };

    const resolvers = {
        callbackMap: {},
        componentMap: {
        },
    };
    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={exposureVM}
            overrideProps={overrideProps}
            onValueChange={writeValue}
            showErrors={showErrors}
            onValidationChange={onValidate}
            {...resolvers}
        />
    );
}

export default GLExposureDetails;
